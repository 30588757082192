import * as React from 'react';
import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider } from '@mui/material/styles';
import AppAppBar from './components/AppAppBar.tsx';
import Hero from './components/Hero.tsx';
import LogoCollection from './components/LogoCollection.tsx';
import Highlights from './components/Highlights.tsx';
import Pricing from './components/Pricing.tsx';
import Features from './components/Features.tsx';
import Testimonials from './components/Testimonials.tsx';
import FAQ from './components/FAQ.tsx';
import Footer from './components/Footer.tsx';
import Link from '@mui/material/Link';


export default function LandingPage() {
  const [mode] = React.useState<PaletteMode>('light');
  const [showCustomTheme] = React.useState(true);


  return (
    <ThemeProvider theme={showCustomTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} />
      <Hero />
      <Box sx={{ bgcolor: 'background.default' }}>
        {/* <LogoCollection /> */}
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
        <Box textAlign="center" p={2}>
          <Link href="/terms-and-conditions" color="primary">
            Terms & Conditions
          </Link>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
