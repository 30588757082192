import React, { useEffect } from 'react';

interface PipedriveFormProps {
  webformUrl: string;
}

const PipedriveForm: React.FC<PipedriveFormProps> = ({ webformUrl }) => {
  useEffect(() => {
    // Create script element
    const script = document.createElement('script');
    script.src = 'https://webforms.pipedrive.com/f/loader';
    script.async = true;

    // Append script to document
    document.body.appendChild(script);

    // Cleanup function
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div 
      className="pipedriveWebForms" 
      data-pd-webforms={webformUrl}
    />
  );
};

export default PipedriveForm;