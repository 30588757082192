import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import InputLabel from '@mui/material/InputLabel';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PipedriveForm from './PipedriveForm.tsx';

import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';

import { useState } from 'react';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebaseConfig.ts';
import logo2 from '../assets/logo2.png';


export default function Hero() {
  const [email, setEmail] = useState('');
  const [practiceName, setPracticeName] = useState('');
  const [practicePostcode, setPracticePostcode] = useState('');
  const [username, setUsername] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleEmailChange = (event) => { setEmail(event.target.value);};
  const handlePracticeNameChange = (event) => setPracticeName(event.target.value);
  const handleUsernameChange = (event) => setUsername(event.target.value);
  const handlePracticePostcodeChange = (event) => setPracticePostcode(event.target.value);

  const handleSubmit = async () => {
    if (!email) {
      alert('Please enter an email address.');
      return;
    }
    // checks format
    if (!email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
      alert('Please enter a valid email address.');
      return;
    }
    

    // Query to check if the email already exists in the collection
    const emailQuery = query(collection(db, 'waitlist'), where("email", "==", email));
    const querySnapshot = await getDocs(emailQuery);

    if (!querySnapshot.empty) {
      let alreadySubmitted = false;
      querySnapshot.forEach((doc) => {
        if (doc.data().submissions >= 1) { // Assuming you want to limit submissions to 1 per email
          alert('You have reached the submission limit.');
          alreadySubmitted = true;
        }
      });

      if (alreadySubmitted) return;
    }

    // If the email does not exist or hasn't reached the submission limit, add a new document
    try {
      await addDoc(collection(db, 'waitlist'), { email, practiceName, practicePostcode, username });
      alert('Successfully added to the waitlist!');
    } catch (error) {
      console.error('Error adding email to Firestore:', error);
      alert('Failed to submit email.');
    }

    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 120000); // Re-enable the button after 2 minutes
  };
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)'
            : 'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 26, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          alignItems="center"
          useFlexGap
          sx={{ width: { xs: '100%', sm: '70%' } }}
        >
          <Container
  sx={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', // Centers content vertically in the container
    minHeight: '80vh', // Ensures the container takes at least the height of the viewport
  }}
>
<Box
  component="img"
  src={logo2}
  alt="Logo"
  sx={{
    width: {
      xs: '50vw',  // 50% of the viewport width on extra small screens
      sm: '30vw',  // 30% of the viewport width on small screens
      md: '20vw'   // 20% of the viewport width on medium screens
    },
    height: 'auto',  // Maintain the aspect ratio of the image
    margin: '0 auto', // Center the image horizontally
    display: 'block'  // Ensure the image does not inline with text
  }}
/>
</Container>
            
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ pt: 2, pb: 10, width: { sm: '100%', md: '80%' } }}
          >
            Our integrated procurement platform is designed to streamline purchasing with transparency and exclusive discounts.
            We empower dental practices with collective buying power, enabling significant savings on supplies and equipment.
            As your dedicated partner, we simplify procurement, save you money, and enhance practice efficiency.
          </Typography>

          {/* Pipedrive Form Integration */}
          <PipedriveForm 
              webformUrl="https://webforms.pipedrive.com/f/c5hP1UrlvofXFp1BIascuT5IbYoqt8qLxZN8hM46VumSew0SR6Bg1c9P5FrvBpAOvp" 
            />

            {/* 
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ width: { sm: '100%', md: '80%' } }}
          >
            Secure your spot today by joining our waiting list today.
          </Typography>
          

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={1}
            useFlexGap
            sx={{ pt: 2,width: { xs: '100%', sm: 'auto' } }}
          >
            <InputLabel htmlFor="email-hero" sx={visuallyHidden}>Email</InputLabel>
            <TextField 
              id="email-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Email"
              inputProps={{ autoComplete: 'off', 'aria-label': 'Enter your email address' }}
              value={email} onChange={handleEmailChange}
            />
            <TextField
              id="clinic-name-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your clinic name"
              placeholder="Clinic name"
              inputProps={{ autoComplete: 'off', 'aria-label': 'Enter your clinic name' }}
              value={practiceName}
              onChange={handlePracticeNameChange}
            />
            <TextField
              id="clinic-postcode-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your clinic postcode"
              placeholder="Clinic postcode"
              inputProps={{ autoComplete: 'off', 'aria-label': 'Enter your clinic postcode' }}
              value={practicePostcode}
              onChange={handlePracticePostcodeChange}
            />
            <TextField
              id="username-hero"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your name"
              placeholder="Your name"
              inputProps={{ autoComplete: 'off', 'aria-label': 'Enter your name' }}
              value={username}
              onChange={handleUsernameChange}
            />
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isButtonDisabled}>
              Submit
            </Button>
          </Stack>

            */
          }

            <Typography variant="caption" textAlign="center">
            By clicking &quot;Submit&quot; you agree to our&nbsp;
            <Link href="/terms-and-conditions" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
